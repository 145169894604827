<template>
  <div style="padding: 20px; background: #fff">
    <el-tabs v-model="activeName"  @tab-click="handleClick">
      <el-tab-pane name="flower" label="花材管理"></el-tab-pane>
      <el-tab-pane name="container" label="器皿管理"></el-tab-pane>
    </el-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
    data(){
        return{
            activeName:'flower'
        }
    },
    methods:{
        handleClick(item){
            this.$router.push(`/materials/${item.name}/list`);
        }
    }
};
</script>

<style>
</style>